function NoPage() {
  return (
    <div
      className="flex justify-center items-center h-screen font-title text-white"
      style={{ fontSize: "50px", marginTop: "-60px" }}
    >
      Error 404: this page does not exist
    </div>
  );
}

export default NoPage;
